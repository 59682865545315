

export const settings = (length) => {
  return {
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    infinite: length >= 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: length >= 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: length >= 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: length >= 2,
        },
      },
    ],
  };
};

export const categorySettings = (length) => {
  return {
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    infinite: length >= 6,
    touchMove: length >= 6,
    swipeToSlide: length >= 6,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: length >= 4,
          touchMove: length >= 4,
          swipeToSlide: length >= 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: length >= 3,
          touchMove: length >= 3,
          swipeToSlide: length >= 3,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: length >= 2,
          touchMove: length >= 2,
          swipeToSlide: length >= 2,
        },
      },
    ],
  };
};
