import React from "react";
import { useNavigate } from "react-router-dom";

const BrandCard = ({ brandDetails }) => {
	const navigate = useNavigate();

	return (
		<div className="xl:col-span-3 md:col-span-4 col-span-6 hover:cursor-pointer p-1 md:p-4">
			<div className="h-full w-full bg-white shadow-md rounded-lg overflow-hidden">
				<div onClick={() => navigate(`/all-brands/${brandDetails?.slug}`)}>
					<img
						src={brandDetails?.logo}
						className="w-full h-40 lg:h-60 object-fill"
						alt="Brand"
					/>
				</div>
				<div className="text-black my-1 text-md font-bold py-2 text-center">
					{brandDetails?.name}
				</div>
			</div>
		</div>
	);
};

export default BrandCard;
