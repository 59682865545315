import React from "react";

const EmptyList = ({ title, icon }) => {
	return (
		<div>
			<div className="flex items-center justify-center">
				<div>
					<div className="flex justify-center">
						<img src={icon} alt="icon" />
					</div>
					<div className="uppercase text-center text-2xl font-bold">
						{title}
					</div>
					<div className="text-center text-[#5B5A5A] mt-2">
						You invite you to get acquainted with an assortment of our shop.
						Surely you can find something for yourself!
					</div>
				</div>
			</div>
			<div className="flex justify-center mt-10">
				<button className="text-white bg-[#59008E] px-6 py-2 rounded-full font-medium text-sm">
					Return To Shop
				</button>
			</div>
		</div>
	);
};

export default EmptyList;
