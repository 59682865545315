import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";

import { AuthContext } from "../contextApi/AuthContext";

const Schema = Yup.object().shape({
	name: Yup.string().required("Name is required"),
	email: Yup.string().email().required("Email is required"),
	password: Yup.string().required("Password is required"),
	confirmPassword: Yup.string().oneOf(
		[Yup.ref("password"), null],
		"Passwords must match"
	),
});

const SignupForm = () => {
	const { handleSignup } = useContext(AuthContext);

	return (
		<Formik
			validateOnChange={false}
			validationSchema={Schema}
			initialValues={{ name: "", email: "", password: "", confirmPassword: "" }}
			onSubmit={(values) => handleSignup(values)}
		>
			{({ errors, touched }) => {
				return (
					<Form>
						<div>
							<div className="mt-3 font-medium">
								<div>Full Name *</div>
								<Field
									name="name"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
								/>
								{errors.name && (
									<div className="text-red-400">{errors?.name}</div>
								)}
							</div>
							<div className="mt-3 font-medium">
								<div>Email address *</div>
								<Field
									type="email"
									name="email"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
								/>
								{errors.email && (
									<div className="text-red-400">{errors?.email}</div>
								)}
							</div>
							<div className="mt-3 font-medium">
								<div>Password *</div>
								<Field
									name="password"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
									type="password"
								/>
								{errors.password && (
									<div className="text-red-400">{errors?.password}</div>
								)}
							</div>
							<div className="mt-3 font-medium">
								<div>Confirm Password *</div>
								<Field
									name="confirmPassword"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
									type="password"
								/>
								{errors.confirmPassword && (
									<div className="text-red-400">{errors?.confirmPassword}</div>
								)}
							</div>
							<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
								Register
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default SignupForm;
