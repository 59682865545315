import React from "react";
import CheckoutDeliveryTable from "../CheckoutDeliveryTable";
import CheckoutRadioButton from "./CheckoutRadioButton";

const Payment = ({ userInformation }) => {
	const tableData = [
		{ field: "Contact", value: userInformation?.email },
		{ field: "Address", value: userInformation?.streetAddress },
		{ field: "Method", value: "GLS Pickup Method" },
	];

	return (
		<div>
			<div className="w-full bg-white p-6 drop-shadow-2xl">
				<CheckoutDeliveryTable tableData={tableData} />
			</div>
			<div className="my-6">All Transactions Are Secure And Encrypted</div>
			<div className="mt-4">
				<CheckoutRadioButton label={"Cash on delivery"} checked={true} />
			</div>
			<div className="mt-6">Pay With Cash Upon Delivery</div>
			<div className="mt-4">
				<CheckoutRadioButton
					label={"Pay using a credit or debit card via Online Payments"}
					checked={false}
				/>
			</div>
			<div className="mt-4">
				<CheckoutRadioButton label={""} checked={false} />
			</div>
			<div className="mt-4">
				Your personal data will be used to process your order, support your
				experience throughout this website , and for other purposes described in
				our <span className="text-[#0576AC]">privacy policy</span>.
			</div>
		</div>
	);
};

export default Payment;
