import React, { useContext, useEffect, useState } from "react";
import CheckoutCartCard from "./CheckoutCartCard";
import { getCartSummary } from "../services/cart";
import { MainContext } from "../contextApi/MainContext";
import { useNavigate } from "react-router-dom";

const CheckoutItems = () => {
	const [cartSummary, setCartSummary] = useState();
	const [products, setProducts] = useState([]);
	const { baseColor } = useContext(MainContext);
	const navigate = useNavigate();

	const handleGetCartSummary = async () => {
		try {
			let data = {};
			const userId = localStorage.getItem("user_id");
			const tempUserId = localStorage.getItem("temp_user_id");
			if (userId) {
				data.user_id = userId;
			} else if (tempUserId) {
				data.temp_user_id = tempUserId;
			}
			const response = await getCartSummary(data);
			console.log("REsponse from getting cart summary: ", response);
			if (!response?.data?.cart_detail) {
				navigate("/");
			}
			setCartSummary(response?.data);
			setProducts(response?.data?.product_detail || []);
		} catch (error) {
			console.log("Error from getting cart summary: ", error);
		}
	};

	useEffect(() => {
		handleGetCartSummary();
	}, []);

	return (
		<div>
			<div className="bg-white drop-shadow-xl p-4">
				<div className="text-xl">Your Cart</div>
				{products?.map((product, index) => (
					<CheckoutCartCard
						productDetails={product}
						key={index}
						isLastItem={index === products?.length - 1}
					/>
				))}
				<div className="grid grid-cols-12 gap-4 py-4 border-t border-[#D9D9D9]">
					<div className="col-span-8">
						<input
							className="p-3 rounded border border-[#D9D9D9] focus:outline-none w-full"
							placeholder="Enter Promo Code"
						/>
					</div>
					<div className="col-span-4">
						<button
							className="w-full p-3 text-white rounded-md"
							style={{ backgroundColor: baseColor }}
						>
							Apply
						</button>
					</div>
				</div>
			</div>
			<div className="my-6 flex items-center justify-between text-[#616161] text-sm">
				<span>SUBTOTAL</span>
				<span>{cartSummary?.sub_total}</span>
			</div>
			<div className="mt-6 flex items-center justify-between text-[#616161] text-sm">
				<span>SHIPPING</span>
				<span>{cartSummary?.shipping_cost}</span>
			</div>
			<div className="my-2 py-2 flex items-center justify-between border-t border-black">
				<span>TOTAL</span>
				<span>{cartSummary?.grand_total}</span>
			</div>
		</div>
	);
};

export default CheckoutItems;
