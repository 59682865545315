import { api } from "./axiosInstance"

export const addToCart = (data) => {
  return api.post(`carts/add`, data);
}

export const getCartList = (data) => {
  return api.post(`/carts`, data, { headers: { Authorization: `Bearer ${localStorage.getItem("Auth")}` } });
}

export const changeCartQuantity = (data) => {
  return api.post(`/carts/change-quantity`, data);
}

export const getCartCount = (data) => {
  return api.post(`/cart-count`, data);
}

export const processCart = (data) => {
  return api.post(`/carts/process`, data);
}

export const getCartSummary = (data) => {
  return api.post(`/cart-summary`, data);
}

export const removeItemFromCart = (id) => {
  return api.delete(`/carts/${id}`);
}