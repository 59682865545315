import React from "react";

import HeartIcon from "../../assets/images/heartIcon.svg";
import EmptyList from "../../components/EmptyList";

const Wishlist = () => {
	return (
		<div>
			<EmptyList title="Your wishlist is empty" icon={HeartIcon} />
		</div>
	);
};

export default Wishlist;
