import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  addToCart,
  changeCartQuantity,
  getCartCount,
  getCartList,
  processCart,
  removeItemFromCart,
} from "../services/cart";

const CartContext = createContext({
  handleAddToCart: () => { },
  itemsList: [],
  listLoader: false,
  handleChangeCartQuantity: () => { },
  handleRemoveItemFromCart: () => { },
  grandTotal: "$0.00",
  cartCount: 0,
  handleProcessCart: () => { },
  handleRefreshItems: () => { }
});

const CartProvider = ({ children }) => {
  const [itemsList, setItemsList] = useState([]);
  const [listLoader, setListLoader] = useState(false);
  const [grandTotal, setGrandTotal] = useState("$0.00");
  const [cartCount, setCartCount] = useState(0);

  const handleAddToCart = async (productId, quantity) => {
    try {
      const data = {
        id: productId,
        quantity: quantity,
      };
      const userId = localStorage.getItem("user_id");
      const tempUserId = localStorage.getItem("temp_user_id");
      if (userId) {
        data.user_id = userId;
      } else if (tempUserId) {
        data.temp_user_id = tempUserId;
      }
      const response = await addToCart(data);
      console.log("Response from adding item to cart: ", response);
      // if (!(userId || tempUserId)) {
      //   const data = response?.data?.cart;
      //   const tempId = data?.[data?.length - 1]?.temp_user_id;
      //   localStorage.setItem("temp_user_id", tempId);
      // }
      handleGetCartList();
      handleGetCartCount();
    } catch (error) {
      console.log("Error from adding item to cart: ", error);
    }
    return;
  };

  const handleGetCartList = async () => {
    setListLoader(true);
    try {
      const data = {};
      const user_id = localStorage.getItem("user_id");
      const tempUserId = localStorage.getItem("temp_user_id");
      if (user_id) {
        data.user_id = user_id;
      } else if (tempUserId) {
        data.temp_user_id = tempUserId;
      }
      const response = await getCartList(data);
      console.log("Response from getting cart list: ", response);
      setItemsList(response?.data?.data);
      setGrandTotal(response?.data?.grand_total);
      setListLoader(false);
    } catch (error) {
      console.log("Error from getting cart list: ", error);
      setListLoader(false);
    }
  };

  const handleChangeCartQuantity = async (id, quantity) => {
    try {
      const data = { id, quantity };
      const response = await changeCartQuantity(data);
      console.log("Response from changing cart quantity: ", response);
      toast.success(response.data.message);
      handleGetCartList();
      handleGetCartCount();
    } catch (error) {
      console.log("Error from changing cart quantity: ", error);
    }
  };

  const handleRemoveItemFromCart = async (id) => {
    try {
      const response = await removeItemFromCart(id);
      console.log("Response from deleting items from cart: ", response);
      toast.success(response?.data?.message);
      handleGetCartList();
      handleGetCartCount();
    } catch (error) {
      console.log("Error from removing item from cart: ", error);
    }
  };

  const handleGetCartCount = async () => {
    try {
      const data = {};
      const user_id = localStorage.getItem("user_id");
      const tempUserId = localStorage.getItem('temp_user_id');
      if (user_id) {
        data.user_id = user_id;
      } else if (tempUserId) {
        data.temp_user_id = tempUserId;
      }
      const response = await getCartCount(data);
      console.log("Response from getting cart count: ", response);
      setCartCount(response?.data?.count);
    } catch (error) {
      console.log("Error from getting cart count: ", error);
    }
  };

  const handleProcessCart = async () => {
    try {
      const cartIds = itemsList?.[0]?.cart_items?.map((item) => item.id);
      const cartQuantities = itemsList?.[0]?.cart_items?.map((item) => item.quantity);
      const data = { cart_ids: cartIds.join(","), cart_quantities: cartQuantities.join(",") };
      console.log("Payload of processing cart quantities: ", data);
      const response = await processCart(data);
      console.log("Response from processing cart: ", response);
      return Promise.resolve(response);
    } catch (error) {
      console.log("Error from processing cart: ", error);
      toast.error(error?.response?.data?.message);
      return Promise.reject(error);
    }
  }

  const handleRefreshItems = () => {
    handleGetCartList();
    handleGetCartCount();
  }

  useEffect(() => {
    handleGetCartList();
    handleGetCartCount();
  }, []);

  return (
    <CartContext.Provider
      value={{
        itemsList,
        handleAddToCart,
        listLoader,
        handleChangeCartQuantity,
        handleRemoveItemFromCart,
        grandTotal,
        cartCount,
        handleProcessCart,
        handleRefreshItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };
