import React, { useContext, useState } from "react";
import { MainContext } from "../contextApi/MainContext";
import Star from "./Star";

const ProductReviews = ({ handleSubmitReview }) => {
	const { baseColor, secondaryBaseColor } = useContext(MainContext);
	const [rating, setRating] = useState(1);
	const [comment, setComment] = useState("");

	const ratingData = [[1], [1, 2], [1, 2, 3], [1, 2, 3, 4], [1, 2, 3, 4, 5]];

	const handleClickReview = () => {
		handleSubmitReview({ rating: rating, comment: comment });
	};

	const user = localStorage.getItem("Auth");

	return user ? (
		<div>
			<div>Your rating *</div>
			<div className="flex items-center flex-wrap gap-10">
				{ratingData.map((innerArr, index) => (
					<div
						key={index}
						onClick={() => {
							setRating(index + 1);
						}}
						className="flex nowrap"
					>
						{innerArr.map((star, indexJ) => (
							<Star key={indexJ} selected={rating === innerArr.length} />
						))}
					</div>
				))}
			</div>
			<div>Your Review *</div>
			<textarea
				value={comment}
				onChange={(e) => setComment(e.target.value)}
				rows={5}
				className="w-full focus:outline-none focus:border-2 border p-4"
				style={{ borderColor: baseColor }}
			/>
			<div className="flex justify-end mt-2 text-white">
				<button
					className="py-3 px-10 rounded-md"
					style={{ backgroundColor: secondaryBaseColor }}
					onClick={handleClickReview}
				>
					Submit
				</button>
			</div>
		</div>
	) : (
		<div className="flex justify-center text-2xl font-bold">
			Please login to add review
		</div>
	);
};

export default ProductReviews;
