import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProductCard from "../components/ProductCard";
import ProductDescription from "../components/ProductDescription";
import ProductDetailCard from "../components/ProductDetailCard";
import ProductDetails from "../components/ProductDetails";
import ProductReviews from "../components/ProductReviews";
import Spinner from "../components/Spinner";
import {
	getProductDetails,
	getTopRelatedProducts,
	submitReview
} from "../services/products";

const ProductDetail = () => {
	const [selectedTab, setSelectedTab] = useState("description");
	const [productDetails, setProductDetails] = useState();
	const [relatedProducts, setRelatedProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingRelatedProducts, setIsLoadingRelatedProducts] =
		useState(false);

	const { id } = useParams();

	const handleGetRelatedProducts = async (slug) => {
		setIsLoadingRelatedProducts(true);
		try {
			const response = await getTopRelatedProducts(slug);
			console.log("Response from getting related product details: ", response);
			setRelatedProducts(response.data.data);
			setIsLoadingRelatedProducts(false);
		} catch (error) {
			console.log("Error from getting related prodcut deails: ", error);
			setIsLoadingRelatedProducts(false);
		}
	};

	const handleGetProductDetails = async () => {
		setIsLoading(true);
		try {
			const response = await getProductDetails(id);
			console.log("Response from getting product details: ", response);
			setProductDetails(response.data.data);
			handleGetRelatedProducts(response?.data?.data?.slug);
			setIsLoading(false);
		} catch (error) {
			console.log("Error from getting prodcut deails: ", error);
			setIsLoading(false);
		}
	};

	const handleSubmitReview = async (value) => {
		try {
			const data = {
				product_id: id,
				user_id: localStorage.getItem("user_id"),
				rating: value.rating,
				comment: value.comment,
			};
			const response = await submitReview(data);
			console.log("Response from submitting reviews: ", response);
			if (response?.data?.result === false) {
				toast.error(response?.data?.message);
			}
		} catch (error) {
			console.log("Error from submitting review: ", error);
		}
	};

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		handleGetProductDetails();
	}, [id]);

	return isLoading ? (
		<Spinner />
	) : (
		<div className="px-5 2xl:px-60">
			<div className="my-20">
				{/* Breadcrumb */}
				<div className="flex items-center gap-3 text-sm text-[#838383]">
					<Link to="/">Home</Link>
					<div>{">"}</div>
					<div>{productDetails?.category_name}</div>
				</div>

				{/* Product Title */}
				<div className="text-2xl sm:text-3xl lg:text-4xl font-bold mt-4">
					{productDetails?.name}
				</div>
			</div>

			{/* Product Grid */}
			<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 sm:gap-10">
				{/* Left Column: Product Detail Card */}
				<div className="col-span-1 sm:col-span-2 lg:col-span-4">
					<ProductDetailCard productDetails={productDetails} />
				</div>

				{/* Right Column: Product Details */}
				<div className="col-span-1 sm:col-span-2 lg:col-span-8">
					<ProductDetails productDetails={productDetails} />
				</div>
			</div>

			{/* Tab Navigation */}
			<div className="flex items-center gap-10 mt-10 text-xl sm:text-2xl">
				<button
					onClick={() => setSelectedTab("description")}
					className={`${
						selectedTab === "description" ? "text-black" : "text-[#838383]"
					}`}
				>
					Description
				</button>
				<button
					onClick={() => setSelectedTab("review")}
					className={`${
						selectedTab === "review" ? "text-black" : "text-[#838383]"
					}`}
				>
					Review
				</button>
			</div>

			{/* Tab Content */}
			<div className="border-y border-[#BBBBBB] py-8">
				{selectedTab === "description" ? (
					<ProductDescription description={productDetails?.description} />
				) : (
					<ProductReviews handleSubmitReview={handleSubmitReview} />
				)}
			</div>

			{/* Related Products */}
			{isLoadingRelatedProducts ? (
				<Spinner />
			) : relatedProducts?.length ? (
				<div className="mt-10">
					<div className="text-2xl sm:text-3xl lg:text-4xl font-bold">
						Related Products
					</div>
					<div className="grid grid-cols-12 gap-4 mt-4">
						{relatedProducts.map((product, index) => (
							<ProductCard key={index} productDetails={product} />
						))}
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ProductDetail;
