import React, { useState } from "react";

import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";

const AuthDropdown = () => {
	const [selectedTab, setSelectedTab] = useState("login");

	return (
		<div className="absolute -right-14 xs:-right-24 sm:right-0 bg-white px-4 pt-10 pb-6 w-auto text-[#1F1F1F] z-[999]">
			<div className="flex items-center justify-between w-60 xs:w-80 text-sm">
				<button
					onClick={() => setSelectedTab("login")}
					className={`text-center w-full pb-2 ${
						selectedTab === "login"
							? "border-b-2 border-[#9C9C9C] font-medium"
							: "border-b border-[#9C9C9C] text-[#9C9C9C]"
					}`}
				>
					LOGIN
				</button>
				<button
					onClick={() => setSelectedTab("register")}
					className={`text-center w-full pb-2 ${
						selectedTab === "register"
							? "border-b-2 border-[#9C9C9C]"
							: "border-b border-[#9C9C9C] text-[#9C9C9C]"
					}`}
				>
					REGISTER
				</button>
			</div>
			{selectedTab === "login" ? <LoginForm /> : <SignupForm />}
		</div>
	);
};

export default AuthDropdown;
