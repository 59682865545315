import { createBrowserRouter, Outlet } from "react-router-dom";
import CopyRightRow from "../components/CopyRightRow";
import Footer from "../components/Footer";
import FreeDeliveryBar from "../components/FreeDeliveryBar";
import Header from "../components/Header";
import AllBrands from "../pages/AllBrands";
import AllProducts from "../pages/AllProducts";
import BrandProducts from "../pages/BrandProducts";
import Categories from "../pages/Categories";
import Checkout from "../pages/Checkout";
import ContactUs from "../pages/ContactUs";
import Home from "../pages/Home";
import MyAccount from "../pages/MyAccount";
import AccountDetails from "../pages/myAccount/AccountDetails";
import Addresses from "../pages/myAccount/Addresses";
import Compare from "../pages/myAccount/Compare";
import Dashboard from "../pages/myAccount/Dashboard";
import Downloads from "../pages/myAccount/Downloads";
import Orders from "../pages/myAccount/Orders";
import Wishlist from "../pages/myAccount/Wishlist";
import ProductDetail from "../pages/ProductDetail";

const Layout = ({ children }) => {
  return (
    <>
      <FreeDeliveryBar />
      <Header />
      <Outlet />
      <Footer />
      <CopyRightRow />
    </>
  )
}

export const router = createBrowserRouter([
  {
    path: '/', element: <Layout />, children: [
      { index: true, element: <Home /> },
      { path: 'categories/:id', element: <Categories /> },
      { path: 'product/:id', element: <ProductDetail /> },
      { path: 'contact-us', element: <ContactUs /> },
      { path: 'checkout', element: <Checkout /> },
      { path: 'all-products', element: <AllProducts /> },
      { path: 'all-brands', element: <AllBrands /> },
      { path: 'all-brands/:slug', element: <BrandProducts /> },
      {
        path: "/my-account", element: <MyAccount />, children: [
          { path: "dashboard", element: <Dashboard /> },
          { path: "orders", element: <Orders /> },
          { path: "downloads", element: <Downloads /> },
          { path: "addresses", element: <Addresses /> },
          { path: "account-details", element: <AccountDetails /> },
          { path: "wishlist", element: <Wishlist /> },
          { path: "compare", element: <Compare /> }
        ]
      }
    ]
  },
]);