import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['SYSTEM-KEY'] = process.env.REACT_APP_BACKEND_SYSTEM_KEY;

  return config;
}, (error) => {
  return Promise.reject((error));
});