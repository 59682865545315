import React from "react";
import ProductImage from "../assets/images/product_image.png";

const ProductDetailCard = ({ productDetails }) => {
	const smallImages = [ProductImage, ProductImage, ProductImage];

	return (
		<div className="border border-[#59595969] rounded-md p-2">
			{/* Main Image */}
			<img
				src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${productDetails?.thumbnail?.file_name}`}
				alt="Main Product"
				className="object-contain w-full h-96 rounded-md" // Set height for the main image
			/>
			{/* Small Images Container */}
			{/* <div className="flex items-center justify-between gap-2 mt-4">
				{smallImages.map((image, index) => (
					<div
						key={index}
						className="border border-[#59595969] rounded-md flex-1 p-1"
					>
						<img
							src={image}
							alt={`Product Thumbnail ${index + 1}`}
							className="h-24 w-full object-cover rounded-md" // Adjusted height for small images
						/>
					</div>
				))}
			</div> */}
		</div>
	);
};

export default ProductDetailCard;
