import React from "react";

const CheckoutRadioButton = ({
	label,
	checked,
	hasDescription,
	description,
	price,
}) => {
	return (
		<div>
			<div
				className={`w-full ${
					checked ? "bg-[#DEE6FE]" : "bg-[#EEEAE7] border border-[#C4C3C3]"
				} p-6 drop-shadow-md`}
			>
				<div class="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<input
							id="disabled-radio-1"
							type="radio"
							checked={checked}
							name="disabled-radio"
							class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
						/>
						<label for="disabled-radio-1" class="ms-2 font-semibold">
							{label}
						</label>
					</div>
					{!!price && <span>{price.toFixed(2)}</span>}
				</div>
				{hasDescription && (
					<div className="mb-4 mt-6 bg-[#FAFAFA] p-4">{description}</div>
				)}
			</div>
		</div>
	);
};

export default CheckoutRadioButton;
