import React from "react";
import InputField from "../../components/InputField";

const AccountDetails = () => {
	return (
		<div>
			<div className="w-full text-[#343434]">
				<InputField label="First Name *" placeholder="First name" />
				<InputField label="Last Name *" placeholder={"Last name"} />
				<div>
					<InputField label="Display Name *" placeholder={"Display name"} />
					<span className="text-[#838383] italic">
						This will be how your name will be displayed in the account section
						and in reviews
					</span>
				</div>
				<InputField label="Email address *" placeholder="name@example.com" />
				<div className="border border-gray mt-10 p-4 relative">
					<div className="absolute -top-[0.4px] left-0 ml-5 -translate-y-3 bg-white px-1 text-sm">
						PASSWORD CHANGE
					</div>
					<InputField
						label="Current password (leave blank to leave unchanged)"
						placeholder="Current password"
					/>
					<InputField
						label="New Password (leave blank to leave unchanged)"
						placeholder="New password"
					/>
					<InputField
						label="Confirm New Password"
						placeholder="Confirm new password"
					/>
				</div>
				<button className="mt-4 bg-[#59008E] text-white rounded-full py-2 px-6">
					Save Changes
				</button>
			</div>
		</div>
	);
};

export default AccountDetails;
