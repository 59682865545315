import React from "react";
import NoDataAvailableDashboard from "../../components/NoDataAvailableDashboard";

const Downloads = () => {
	return (
		<div>
			<NoDataAvailableDashboard text="No downloads available yet." />
		</div>
	);
};

export default Downloads;
