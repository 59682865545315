import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const OriginalPrice = ({ price }) => {
	const { secondaryBaseColor } = useContext(MainContext);
	return (
		<div
			className="flex justify-center no-wrap line-through text-white py-1 px-16 font-bold mt-2 w-full rounded text-lg"
			style={{ backgroundColor: secondaryBaseColor }}
		>
			${price}
		</div>
	);
};

export default OriginalPrice;
