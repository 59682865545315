import React from "react";
import EmptyList from "../../components/EmptyList";

const Compare = () => {
	return (
		<div>
			<EmptyList title="Your compare is empty" />
		</div>
	);
};

export default Compare;
