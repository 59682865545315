import { api } from "./axiosInstance"

export const loginUser = (loginData) => {
  return api.post(`/auth/login`, loginData);
}

export const signupUser = (signUpData) => {
  return api.post(`/auth/signup`, signUpData);
}

export const getUuid = () => {
  return api.get(`/products/get-uuid`);
}