import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

import ChevronDownIcon from "../assets/images/chevronDownIcon.svg";
import Information from "../components/checkoutForms/Information";
import Payment from "../components/checkoutForms/Payment";
import Shipping from "../components/checkoutForms/Shipping";
import CheckoutItems from "../components/CheckoutItems";
import { addUserDetails, completeOrder } from "../services/checkout";
import { MainContext } from "../contextApi/MainContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../contextApi/CartContext";

const validationSchema = Yup.object().shape({
	email: Yup.string().email().required("This value is required"),
	telephone: Yup.string().required("This value is required"),
	firstName: Yup.string().required("This value is required"),
	lastName: Yup.string().required("This value is required"),
	streetAddress: Yup.string().required("This value is required"),
	postCode: Yup.string().required("This value is required"),
	townOrCity: Yup.string().required("This value is required"),
});

const AccordionItem = ({ header, ...rest }) => (
	<Item
		{...rest}
		header={({ state: { isEnter } }) => (
			<>
				{header}
				<img
					className={`ml-auto transition-transform duration-200 ease-out ${
						isEnter && "rotate-180"
					}`}
					src={ChevronDownIcon}
					alt="Chevron"
				/>
			</>
		)}
		className="border-b"
		buttonProps={{
			className: ({ isEnter }) =>
				`flex w-full justify-between items-center gap-2 p-4 text-left hover:bg-slate-100 ${
					isEnter && "bg-slate-200"
				}`,
		}}
		contentProps={{
			className: "transition-height duration-200 ease-out",
		}}
		panelProps={{ className: "p-4" }}
	/>
);

const Checkout = () => {
	const { secondaryBaseColor } = useContext(MainContext);
	const { handleRefreshItems } = useContext(CartContext);
	const [formStep, setFormStep] = useState(0);
	const [userInformation, setUserInformation] = useState();
	const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);
	const navigate = useNavigate();

	const formTitles = ["Information", "Shipping", "Payment"];

	const pageDisplay = (formik) => {
		switch (formStep) {
			case 0:
				return <Information formik={formik} />;
			case 1:
				return <Shipping userInformation={userInformation} />;
			case 2:
				return <Payment userInformation={userInformation} />;
		}
	};

	const handleSubmitUserInformation = async (values) => {
		try {
			setUserInformation(values);
			const data = {
				email: values?.email,
				phone: values?.telephone,
				first_name: values?.firstName,
				last_name: values?.lastName,
				address: values?.streetAddress,
				postal_cdoe: values?.postCode,
				city_id: parseInt(values?.townOrCity),
				state_id: 2728,
			};
			const userId = localStorage.getItem("user_id");
			const tempUserId = localStorage.getItem("temp_user_id");
			if (userId) {
				data.user_id = userId;
			} else if (tempUserId) {
				data.temp_user_id = tempUserId;
			}

			console.log("Values are: ", values);
			console.log("Data is: ", data);
			const response = await addUserDetails(data);
			console.log("Response from adding user details to backend: ", response);
			// setFormStep(1);
		} catch (error) {
			console.log("Error from adding user information: ", error);
		}
	};

	const handleOrder = async () => {
		setIsConfirmingOrder(true);
		try {
			const data = {};
			const userId = localStorage.getItem("user_id");
			const tempUserId = localStorage.getItem("temp_user_id");
			if (userId) {
				data.user_id = userId;
			} else if (tempUserId) {
				data.temp_user_id = tempUserId;
			}
			const response = await completeOrder(data);
			setIsConfirmingOrder(false);
			console.log("Response from completing order: ", response);
			toast.success(response?.data?.message);
			navigate("/");
			handleRefreshItems();
		} catch (error) {
			setIsConfirmingOrder(false);
			console.log("Error from completing order: ", error);
		}
	};

	const handleChangeStepForm = (stepNumber) => {
		if (formStep === 1 && stepNumber === 0) {
			setFormStep(stepNumber);
		} else if (formStep === 2) {
			setFormStep(stepNumber);
		}
	};

	const handleNext = async (formik) => {
		const { values, isValid, validateField, setTouched, errors } = formik;
		if (
			(formStep === 0 && !values.firstName,
			!values.email,
			!values.telephone,
			!values.lastName,
			!values.streetAddress,
			!values.postCode,
			!values.townOrCity)
		) {
			validateField(
				"email",
				"telephone",
				"firstName",
				"lastName",
				"streetAddress",
				"postCode",
				"townOrCity"
			);
			setTouched({
				email: true,
				telephone: true,
				firstName: true,
				lastName: true,
				streetAddress: true,
				postCode: true,
				townOrCity: true,
			});
		} else if (
			formStep === 0 &&
			!errors.email &&
			!errors.telephone &&
			!errors.firstName &&
			!errors.lastName &&
			!errors.streetAddress &&
			!errors.postCode &&
			!errors.townOrCity
		) {
			handleSubmitUserInformation(values);
			setFormStep(1);
		}
		// if (formStep === 1 && !values.planOption) {
		// 	setTouched({
		// 		planOption: true,
		// 	});
		// } else
		if (formStep === 1) {
			setFormStep(2);
		}
		if (formStep === 2) {
			handleOrder();
		}
	};

	return (
		<div className="bg-[#EEEAE7]">
			<div className="px-5 2xl:px-60">
				{/* GRIDS */}
				<div className="grid grid-cols-12 gap-4">
					<div className="col-span-12 lg:col-span-7 order-last lg:order-1">
						<div className="flex items-center">
							{formTitles.map((title, index) => (
								<button
									key={index}
									onClick={() => handleChangeStepForm(index)}
									className={`w-full border-b ${
										index === formStep
											? "border-blue-500 text-blue-500"
											: "border-[#BEBEBE] text-[#BEBEBE]"
									} flex flex-col gap-2 lg:flex-row items-center justify-center py-3 text-lg`}
								>
									<div>{index + 1}</div>
									<div> {title}</div>
								</button>
							))}
						</div>
						<Formik
							validateOnBlur={false}
							validateOnChange={false}
							initialValues={{
								email: "",
								telephone: "",
								firstName: "",
								lastName: "",
								streetAddress: "",
								postCode: "",
								townOrCity: "",
							}}
							validationSchema={validationSchema}
						>
							{(formik) => {
								return (
									<Form className="form">
										<div className="font-normal text-2xl my-2">
											Delivery Address
										</div>
										{pageDisplay(formik)}
										<div className="w-full flex flex-col lg:flex-row items-center justify-between my-6">
											<button className="w-full text-[#0576AC] order-last lg:order-1 mt-4">
												{"<<"} Return to cart
											</button>
											<button
												className="text-white rounded-md p-6 w-full lg:order-last lg:w-max text-nowrap"
												onClick={() => handleNext(formik)}
												style={{
													backgroundColor: isConfirmingOrder
														? "gray"
														: secondaryBaseColor,
												}}
											>
												{formStep === 2 && isConfirmingOrder
													? "Confirming Order ..."
													: formStep === 2
													? "Confirm Order"
													: "Continue to Shipping"}
											</button>
										</div>
									</Form>
								);
							}}
						</Formik>
					</div>
					<div className="col-span-12 lg:col-span-5 order-1 lg:order-last py-3">
						<Accordion className="w-full lg:hidden">
							<AccordionItem
								className="w-full"
								header={
									<div className="flex justify-between w-full">
										<div>Show order Summary</div>
										<div>125.00</div>
									</div>
								}
							>
								<CheckoutItems />
							</AccordionItem>
						</Accordion>
						<div className="hidden lg:block">
							<CheckoutItems />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Checkout;
