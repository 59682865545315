import { createContext, useEffect, useState } from "react";
import { loginUser, signupUser } from "../services/auth";
import { toast } from "react-toastify";
import { getUuid } from "../services/auth";

const AuthContext = createContext({
  handleLogin: () => { },
  handleSignup: () => { },
  handleLogout: () => { },
  isLoggedIn: false
});

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleGetUuid = async () => {
    try {
      const response = await getUuid();
      console.log("Response of getting uuid: ", response);
      localStorage.setItem("temp_user_id", response?.data);
    } catch (error) {
      console.log("Error from getting uuid", error);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("Auth");
    if (token) {
      setIsLoggedIn(true);
    } else {
      const tempUserId = localStorage.getItem("temp_user_id");
      if (!tempUserId) {
        handleGetUuid();
      }
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("Auth");
    localStorage.removeItem("user_id");
    setIsLoggedIn(false);
  }

  const handleLogin = async (values) => {
    try {
      const data = { email: values.email, password: values.password, login_by: "email" }
      const response = await loginUser(data);
      console.log("Response from logging user in: ", response);
      localStorage.setItem("Auth", response?.data?.access_token);
      localStorage.setItem("user_id", response?.data?.user?.id);
      setIsLoggedIn(true);
    } catch (error) {
      console.log("Error from loggin user in: ", error);
      toast.error(error?.response?.data?.message || "Unexpected error occured");
    }
  }

  const handleSignup = async (values) => {
    try {
      const data = {
        name: values.name,
        email_or_phone: values.email,
        password: values.password,
        password_confirmation: values.password,
        register_by: 'email'
      }
      console.log("Payload sending for sign up user: ", data);
      const response = await signupUser(data);
      console.log("Response from signing up user: ", response);
      localStorage.setItem("Auth", response?.data?.access_token);
      localStorage.setItem("user_id", response?.data?.user?.id);
      setIsLoggedIn(true);
      toast.success("User created successfully");
    } catch (error) {
      console.log("Error from signing up user: ", error);
      toast.error(error?.response?.data?.message || "Unexpected error occured");
    }
  }

  return (
    <AuthContext.Provider value={{ handleLogin, handleSignup, isLoggedIn, handleLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }