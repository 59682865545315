import React, { useState } from "react";
import { Link } from "react-router-dom";

import CloseIcon from "../assets/images/closeIcon.png";
import { searchProducts } from "../services/products";

const SearchDropdown = ({ setIsSearchOpen }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const handleSearch = async (query) => {
		try {
			const response = await searchProducts(query);
			console.log("Response from searching products: ", response);
			setSearchResults(response?.data?.data);
		} catch (error) {
			console.log("Error from searching products: ", error);
		}
	};

	return (
		<div className="absolute top-0 left-0 z-50 w-full h-[87vh] bg-white shadow-lg transition-transform transform slide-in p-5">
			<div className="flex items-center justify-end">
				<button onClick={() => setIsSearchOpen(false)}>
					<img src={CloseIcon} alt="Close" />
				</button>
			</div>
			<div className="flex justify-center mt-10 font-bold text-xl">
				What are you looking for?
			</div>
			<div className="flex justify-center">
				<div className="relative mt-10 w-1/2">
					<input
						value={searchQuery}
						onChange={(e) => {
							setSearchQuery(e.target.value);
							handleSearch(e.target.value);
						}}
						type="text"
						placeholder="Search for..."
						className="w-full h-12 p-4 border-b border-[#E7E6E6] focus:outline-none bg-[#F8F8F8]"
					/>
					{/* Search Results Dropdown */}
					{searchQuery.length > 0 && searchResults.length > 0 && (
						<div className="absolute top-full left-0 w-full bg-white shadow-lg mt-2 border border-[#E7E6E6] max-h-60 overflow-y-auto z-[100]">
							<ul className="list-none p-0 m-0">
								{searchResults.map((product, index) => (
									<Link
										to={`/product/${product.id}`}
										key={index}
										className="w-full"
										onClick={() => {
											setSearchQuery("");
											setSearchResults([]);
											setIsSearchOpen(false);
										}}
									>
										<li className="p-3 hover:bg-[#F8F8F8] cursor-pointer">
											{product.name}
										</li>
									</Link>
								))}
							</ul>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchDropdown;
