import React from "react";

const AddressInput = ({ placeholder }) => {
	return (
		<div className="w-full">
			<div className="flex items-center justify-between w-full border-b border-[#D9D9D9] pb-2">
				<span>{placeholder}</span>
				<span>ADD</span>
			</div>
			<div className="text-[#838383] mt-2 italic">
				You have not set up this type address yet.
			</div>
		</div>
	);
};

export default AddressInput;
