import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import UserIcon from "../assets/images/userIcon.svg";
import UserImage from "../assets/images/userImage.png";

const MyAccount = () => {
	const dashboardItems = [
		// { name: "Dashboard", icon: UserIcon, link: "/my-account/dashboard" },
		{ name: "Orders", icon: UserIcon, link: "/my-account/orders" },
		// { name: "Downloads", icon: UserIcon, link: "/my-account/downloads" },
		{ name: "Addresses", icon: UserIcon, link: "/my-account/addresses" },
		{
			name: "Account details",
			icon: UserIcon,
			link: "/my-account/account-details",
		},
		// { name: "wishlist", icon: UserIcon, link: "/my-account/wishlist" },
		// { name: "Compare", icon: UserIcon, link: "/my-account/compare" },
		{ name: "Log out", icon: UserIcon, link: "#" },
	];

	const navigate = useNavigate();
	const [selectedItem, setSelectedItem] = useState("Dashboard");

	const onClickSidebarItems = (name, link) => {
		setSelectedItem(name);
		navigate(link);
	};

	return (
		<div>
			<div className="px-0 md:px-10 lg:px-20 xl:px-60">
				<div className="flex items-center justify-center gap-2 uppercase font-bold text-2xl">
					<img src={UserIcon} />
					My Account
				</div>
				<div className="grid grid-cols-12 mt-10">
					<div className="col-span-3">
						<img src={UserImage} />
						<div>nazshabnum35</div>
						<div className="text-[#797979]">nazshabnum35@gmail.com</div>
						<div className="mt-10">
							{dashboardItems.map((item) => (
								<button
									onClick={() => onClickSidebarItems(item.name, item.link)}
									className={`flex items-center gap-2 my-4 ${
										item.name === selectedItem ? "text-[#59008E]" : ""
									}`}
								>
									<img src={item.icon} alt={item.name} />
									{item.name}
								</button>
							))}
						</div>
					</div>
					<div className="col-span-9 border-l border-[#D9D9D9] px-4">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default MyAccount;
