import React from "react";
import { createArray } from "../utils/helpers";

const SortingRow = ({ currentPage, handleChangePage, totalPages }) => {
	return (
		<div className="flex items-center justify-end my-1">
			<div className="flex items-center justify-end gap-3">
				<div className="text-[#838383]">Page: </div>
				<select
					value={currentPage}
					onChange={(e) => handleChangePage(e.target.value)}
					name="number"
					className="p-1 bg-white border border-[#59008E]"
				>
					{createArray(totalPages).map((number, index) => (
						<option value={number} key={index}>{number}</option>
					))}
				</select>
			</div>
		</div>
	);
};

export default SortingRow;
