import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import CartIcon from "../assets/images/cartIconBlack.svg";
import CloseIcon from "../assets/images/closeIcon.png";
import HamburgerIcon from "../assets/images/hamburgerMenu.png";
import SearchIcon from "../assets/images/searchIcon.svg";
import UserIcon from "../assets/images/userIcon.svg";
import { AuthContext } from "../contextApi/AuthContext";
import { CartContext } from "../contextApi/CartContext";
import { MainContext } from "../contextApi/MainContext";
import { getHeaderLogo } from "../services/header";
import AuthDropdown from "./AuthDropdown";
import CartCard from "./CartCard";
import CartDrawer from "./CartDrawer";
import SearchDropdown from "./SearchDropdown";
import Spinner from "./Spinner";

const Header = () => {
	const { itemsList, listLoader, grandTotal, cartCount, handleProcessCart } =
		useContext(CartContext);
	const { businessSettings, secondaryBaseColor, baseColor } =
		useContext(MainContext);
	const { isLoggedIn, handleLogout } = useContext(AuthContext);

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [headerLogo, setHeaderLogo] = useState("");
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
	const navigate = useNavigate();

	const headerLabels = businessSettings.filter(
		(bs) => bs.type === "header_menu_labels"
	);

	const headerLabelLinks = businessSettings.filter(
		(bs) => bs.type === "header_menu_links"
	);

	const handleToggleAuthDropdown = () => {
		setIsOpen(!isOpen);
	};

	const getHeaderLogoIcon = async () => {
		try {
			const response = await getHeaderLogo();
			console.log("Response from getting header logo: ", response);
			setHeaderLogo(response.data.header_logo);
		} catch (error) {
			console.log("Error from getting header logo: ", error);
		}
	};

	const handleClickCheckout = async () => {
		setIsCheckoutLoading(true);
		await handleProcessCart();
		setIsDrawerOpen(false);
		navigate("/checkout");
		setIsCheckoutLoading(false);
	};

	useEffect(() => {
		getHeaderLogoIcon();
	}, []);

	return (
		<>
			<div className="sticky top-0 bg-white z-[999] flex justify-between items-center py-3 px-0 md:px-5 2xl:px-60">
				{/* Logo */}
				<Link to="/">
					<img src={headerLogo} className="h-16 w-auto object-contain" />
				</Link>

				{/* Desktop Navigation Menu */}
				<div className="hidden lg:flex gap-6 text-sm lg:text-base font-medium">
					{headerLabels.length &&
						headerLabelLinks.length &&
						JSON.parse(headerLabels[0].value).map((hl, index) => (
							<Link
								key={index}
								to={JSON.parse(headerLabelLinks[0].value)[index]}
							>
								{hl}
							</Link>
						))}
				</div>

				{/* Right-side Icons (Search, User, Cart) */}
				<div className="flex items-center gap-4 lg:gap-6 text-sm lg:text-base">
					<div>
						<button onClick={() => setIsSearchOpen(!isSearchOpen)}>
							<img src={SearchIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
						</button>
						{isSearchOpen && (
							<SearchDropdown setIsSearchOpen={setIsSearchOpen} />
						)}
					</div>
					{isLoggedIn ? (
						<div className="relative">
							<button
								className="rounded-full w-8 h-8 bg-gray-400 text-white"
								onClick={handleLogout}
							>
								T
							</button>
						</div>
					) : (
						<div className="relative">
							<button onClick={handleToggleAuthDropdown}>
								<img src={UserIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
							</button>
							{isOpen && <AuthDropdown />}
						</div>
					)}
					<div className="hidden sm:block">{grandTotal}</div>
					<div className="relative">
						<button onClick={() => setIsDrawerOpen(true)}>
							<img src={CartIcon} className="h-4 w-4 lg:h-5 lg:w-5" />
						</button>
						<div
							className="absolute -top-2 -right-2 text-xs text-white w-4 h-4 p-px rounded-full flex justify-center items-center"
							style={{ backgroundColor: secondaryBaseColor }}
						>
							{cartCount}
						</div>
					</div>
				</div>

				{/* Mobile Hamburger Menu Button */}
				<div className="lg:hidden flex items-center gap-4">
					<button
						onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
						className="text-lg"
					>
						<img
							src={isMobileMenuOpen ? CloseIcon : HamburgerIcon}
							className="h-6 w-6"
						/>
					</button>
				</div>
			</div>

			{/* Mobile Navigation Menu (visible when `isMobileMenuOpen` is true) */}
			{isMobileMenuOpen && (
				<div className="lg:hidden bg-white shadow-md py-4 px-6">
					<div className="flex flex-col gap-4 text-sm font-medium">
						{headerLabels.length &&
							headerLabelLinks.length &&
							JSON.parse(headerLabels[0].value).map((hl, index) => (
								<Link
									onClick={() => setIsMobileMenuOpen(false)}
									key={index}
									to={JSON.parse(headerLabelLinks[0].value)[index]}
								>
									{hl}
								</Link>
							))}
					</div>
				</div>
			)}

			{/* Cart Drawer */}
			<CartDrawer isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen}>
				<div className="flex flex-col flex-grow">
					<div className="absolute top-6 left-4">
						<button onClick={() => setIsDrawerOpen(false)}>
							<img src={CloseIcon} />
						</button>
					</div>
					<div className="flex justify-center items-center">
						<img src={CartIcon} alt="cart" />
					</div>
					<div className="text-center my-2 text-sm md:text-base">
						SHOPPING CART
					</div>
					{listLoader ? (
						<Spinner />
					) : itemsList?.[0] ? (
						<div className="flex flex-col flex-grow">
							<div className="flex-grow">
								{itemsList?.[0]?.cart_items?.map((item, index) => (
									<div className="px-10 my-6" key={index}>
										<CartCard itemDetails={item} />
									</div>
								))}
							</div>
							<div className="px-10">
								<div className="flex items-center justify-between uppercase font-semibold text-sm md:text-base">
									<span className="text-[#656565]">subtotal</span>
									<span>{itemsList?.[0]?.sub_total}</span>
								</div>
								<button
									className="rounded-full w-full text-white py-2 mt-3 font-medium text-sm md:text-base"
									style={{
										backgroundColor: isCheckoutLoading
											? "gray"
											: secondaryBaseColor,
									}}
									onClick={handleClickCheckout}
									disabled={isCheckoutLoading}
								>
									{isCheckoutLoading ? "Checking out..." : "Checkout"}
								</button>
							</div>
						</div>
					) : (
						<div>
							<div className="px-10 text-center mt-20 text-[#656565]">
								No product in the cart
							</div>
							<div className="flex items-center justify-center mt-4">
								<button
									onClick={() => {
										navigate("/");
										setIsDrawerOpen(false);
									}}
									style={{ backgroundColor: secondaryBaseColor }}
									className="text-white w-max px-6 py-2 rounded-full font-medium"
								>
									Return To Shop
								</button>
							</div>
						</div>
					)}
				</div>
			</CartDrawer>
		</>
	);
};

export default Header;
