import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const DiscountedPrice = ({ price }) => {
	const { baseColor } = useContext(MainContext);
	return (
		<div
			className="flex justify-center no-wrap text-white py-1 px-16 font-bold mt-1 w-full rounded text-lg"
			style={{ backgroundColor: baseColor }}
		>
			${price}
		</div>
	);
};

export default DiscountedPrice;
