import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const FreeDeliveryBar = () => {
	const { baseColor } = useContext(MainContext);

	return (
		<marquee
			className="text-white text-xs py-2"
			style={{ backgroundColor: baseColor }}
		>
			Buy More, Pay Less
		</marquee>
	);
};

export default FreeDeliveryBar;
