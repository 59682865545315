import React from "react";

const InputField = ({ label, placeholder }) => {
	return (
		<div className="mt-4">
			<label className="text-[#838383] font-light">{label}</label>
			<input
				className="py-2 px-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
				placeholder={placeholder}
			/>
		</div>
	);
};

export default InputField;
