import { api } from "./axiosInstance"

export const addUserDetails = (data) => {
    return api.post(`user/shipping/create`, data);
}

export const getCities = () => {
    return api.get('/cities');
}

export const completeOrder = (data) => {
    return api.post(`/order/store`, data)
}