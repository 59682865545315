import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const CheckoutCartCard = ({ productDetails }) => {
	const { baseColor } = useContext(MainContext);

	return (
		<div className="w-full p-4 border-b" style={{ borderColor: "#D9D9D9" }}>
			<div className="flex my-5 gap-4">
				<img
					src={productDetails?.thumbnail_image}
					alt="Product"
					className="w-16 h-16"
				/>
				<div className="w-full text-[#595959]">
					<div>{productDetails?.name}</div>
					<div className="flex items-center gap-2">
						<div
							className="border px-3 py-px"
							style={{ borderColor: baseColor }}
						>
							{productDetails?.quantity}
						</div>
					</div>
				</div>
				<div className="text-nowrap">${productDetails?.total_price}</div>
			</div>
		</div>
	);
};

export default CheckoutCartCard;
