import { createContext, useEffect, useState } from "react";
import { getBusinessSettingsList } from "../services/home";

const MainContext = createContext({
  businessSettings: [],
  setBusinessSettings: () => { },
  baseColor: "",
  secondaryBaseColor: "",
});

const MainProvider = ({ children }) => {
  const [businessSettings, setBusinessSettings] = useState([]);
  const [baseColor, setBaseColor] = useState("");
  const [secondaryBaseColor, setSecondaryBaseColor] = useState("");

  const getBusinessSettings = async () => {
    try {
      const response = await getBusinessSettingsList();
      const responseData = response.data.data;
      setBusinessSettings(responseData);
      const baseColorHash = responseData.find((rd) => rd.type === "base_color").value;
      const secondaryBaseHash = responseData.find((rd) => rd.type === "secondary_base_color").value;
      setBaseColor(baseColorHash);
      setSecondaryBaseColor(secondaryBaseHash);
    } catch (error) {
      console.log("Error from getting header list: ", error);
    }
  }

  useEffect(() => {
    getBusinessSettings();
  }, []);

  return (
    <MainContext.Provider value={{ businessSettings, setBusinessSettings, baseColor, secondaryBaseColor }}>
      {children}
    </MainContext.Provider>
  )
};

export { MainContext, MainProvider };

