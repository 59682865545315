import { api } from "./axiosInstance"

export const getAllProducts = (page) => {
  return api.get(`/products?page=${page}`);
}

export const getTopSellingProducts = () => {
  return api.get('/products/featured');
}

export const getProductBrands = (slug, page) => {
  return api.get(`/products/brand/${slug}?page=${page}`)
}

export const getProductDetails = (id) => {
  return api.get(`/products/${id}`)
}

export const getTopRelatedProducts = (slug) => {
  return api.get(`/products/frequently-bought/${slug}`)
}

export const searchProducts = (productName) => {
  return api.get(`/products/search?name=${productName}`)
}

export const submitReview = (data) => {
  return api.post(`/reviews/submit`, data);
}