import React, { useContext, useState } from "react";

import DeleteIcon from "../assets/images/deleteIcon.svg";
import { CartContext } from "../contextApi/CartContext";

const CartCard = ({ itemDetails }) => {
	const { handleChangeCartQuantity, handleRemoveItemFromCart } =
		useContext(CartContext);
	const [totalCount, setTotalCount] = useState(itemDetails?.quantity || 1);

	const onChangeQuantity = (action) => {
		let newCount = totalCount;
		if (action === "add") {
			newCount += 1;
		} else {
			newCount -= 1;
		}
		setTotalCount(newCount);
		handleChangeCartQuantity(itemDetails.id, newCount);
	};

	return (
		<div className="flex items-center gap-4">
			<img
				src={itemDetails?.product_thumbnail_image}
				alt="Product"
				className="h-24 w-24 object-contain"
			/>
			<div>
				<div className="flex items-center gap-2">
					<span className="font-semibold">{itemDetails?.product_name}</span>
					<button onClick={() => handleRemoveItemFromCart(itemDetails?.id)}>
						<img src={DeleteIcon} alt="delete" />
					</button>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex items-center mt-2">
						<button
							className="py-1 px-2 border border-[#838383]"
							onClick={() => onChangeQuantity("sub")}
						>
							-
						</button>
						<div className="py-1 px-3 border border-[#838383]">
							{totalCount}
						</div>
						<button
							className="py-1 px-2 border border-[#838383]"
							onClick={() => onChangeQuantity("add")}
						>
							+
						</button>
					</div>
					<span className="font-bold">X {itemDetails?.unit_price}</span>
				</div>
			</div>
		</div>
	);
};

export default CartCard;
