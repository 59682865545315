import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";

import { AuthContext } from "../contextApi/AuthContext";

const Schema = Yup.object().shape({
	email: Yup.string().email().required("Email is required"),
	password: Yup.string().required("Password is required"),
});

const LoginForm = () => {
	const { handleLogin } = useContext(AuthContext);

	return (
		<Formik
			validateOnChange={false}
			validationSchema={Schema}
			initialValues={{ email: "", password: "", rememberMe: false }}
			onSubmit={(values) => handleLogin(values)}
		>
			{({ errors, touched }) => {
				return (
					<Form>
						<div>
							<div className="mt-3 font-medium">
								<div>Username or email *</div>
								<Field
									type="email"
									name="email"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
								/>
								{errors.email && (
									<div className="text-red-400">{errors?.email}</div>
								)}
							</div>
							<div className="mt-3 font-medium">
								<div>Password *</div>
								<Field
									name="password"
									className="p-1 border border-[#59008E] w-full focus:border-2 focus:outline-none"
									type="password"
								/>
								{errors.password && (
									<div className="text-red-400">{errors?.password}</div>
								)}
							</div>
							<div className="mt-3 flex items-center justify-between">
								<div className="flex items-center gap-2">
									<Field
										name="rememberMe"
										type="checkbox"
										className="border border-[#C5C5C5]"
									/>
									Remember me
								</div>
								<div>Lost password?</div>
							</div>
							<button
								type="submit"
								className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full"
							>
								Log in
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default LoginForm;
