import React, { useContext, useState } from "react";

import StarsGroupIcon from "../assets/images/starsGroup.png";
import { CartContext } from "../contextApi/CartContext";
import { MainContext } from "../contextApi/MainContext";
import DiscountedPrice from "./DiscountedPrice";
import OriginalPrice from "./OriginalPrice";
import PackComponent from "./PackComponent";
import { getTotalPrice } from "../utils/helpers";
import Star from "./Star";

const ProductDetails = ({ productDetails }) => {
	const { baseColor, secondaryBaseColor } = useContext(MainContext);
	const [selectedPack, setSelectedPack] = useState(
		productDetails?.bundles?.[0] || {}
	);
	const [quantity, setQuantity] = useState(1);
	const { handleAddToCart } = useContext(CartContext);

	const handleClickPack = (bundle) => {
		setQuantity(bundle.quantity);
		setSelectedPack(bundle);
	};

	const handleClickAddToCart = () => {
		handleAddToCart(productDetails.id, quantity);
	};

	const handleChangeQuantity = (e) => {
		const value = e.target.value;
		const regex = /^[0-9]*$/;

		if (!isNaN(value) && value > 0 && regex.test(value)) {
			setQuantity(value);
		}
	};

	return (
		<div className="space-y-6 flex flex-col sm:flex-row sm:space-x-10 sm:space-y-0 gap-10 sm:gap-0">
			<div className="sm:w-2/3 order-last sm:order-first">
				<div className="text-lg sm:text-xl sm:text-2xl font-semibold">
					{productDetails?.name}
				</div>
				<div className="my-1 font-bold text-2xl">
					{productDetails?.rating?.toFixed(1)} <Star selected={true} />
				</div>
				<div>44 reviews</div>

				<div className="flex items-center gap-2">
					<div className="font-semibold">SKU:</div>
					<div>{productDetails?.sku}</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="font-semibold">Category:</div>
					<div>{productDetails?.category_name}</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="font-semibold">Tag:</div>
					{productDetails?.tags.split(",").map((tag, index) => (
						<div key={index}>
							{tag}
							{index + 1 < productDetails?.tags.split(",").length ? "," : ""}
						</div>
					))}
				</div>

				{productDetails?.bundles?.length ? (
					<div className="flex items-center gap-1 md:gap-2 mt-4">
						{productDetails?.bundles.map((bundle, index) => (
							<button
								onClick={() => handleClickPack(bundle)}
								className=""
								key={index}
							>
								<PackComponent
									text={bundle.name}
									selected={selectedPack?.name === bundle.name}
								/>
							</button>
						))}
					</div>
				) : null}

				{/* Add to Cart Section */}
				<div className="flex items-center gap-4 mt-6">
					<div
						className="flex items-center border rounded-md"
						style={{ borderColor: baseColor }}
					>
						<input
							onChange={handleChangeQuantity}
							value={quantity}
							type="text"
							className="w-10 px-2 border-0 focus:outline-none"
						/>
						<div className="text-[#575757]">
							<button
								onClick={() => setQuantity(quantity + 1)}
								className="bg-[#D9D9D9] w-full px-1"
							>
								+
							</button>
							<button
								onClick={() => {
									if (quantity > 1) {
										setQuantity(quantity - 1);
									}
								}}
								className="bg-[#D9D9D9] w-full px-1"
							>
								-
							</button>
						</div>
						<button
							onClick={handleClickAddToCart}
							className="text-white py-3.5 px-2 uppercase text-sm font-semibold text-nowrap"
							style={{
								backgroundColor: !productDetails?.out_of_stock
									? "gray"
									: secondaryBaseColor,
							}}
							disabled={!productDetails?.out_of_stock}
						>
							{!productDetails?.out_of_stock ? "Out of Stock" : "Add to Cart"}
						</button>
					</div>
				</div>
			</div>

			{/* Price Section */}
			<div>
				<div
					className="text-end text-sm sm:text-base"
					style={{ color: baseColor }}
				>
					{productDetails?.current_stock} in stock
				</div>
				{selectedPack?.quantity === 1 ? (
					productDetails?.has_discount ? (
						<>
							<OriginalPrice
								price={getTotalPrice(productDetails?.unit_price, quantity)}
							/>
							<DiscountedPrice
								price={getTotalPrice(
									productDetails?.discounted_price,
									quantity
								)}
							/>
						</>
					) : (
						<DiscountedPrice
							price={getTotalPrice(productDetails?.unit_price, quantity)}
						/>
					)
				) : (
					<>
						<OriginalPrice
							price={
								selectedPack?.quantity === quantity
									? selectedPack?.stroked_price?.slice(1)
									: getTotalPrice(productDetails?.unit_price, quantity)
							}
						/>
						<DiscountedPrice
							price={
								selectedPack?.quantity === quantity
									? selectedPack?.final_price?.slice(1)
									: getTotalPrice(productDetails?.discounted_price, quantity)
							}
						/>
					</>
				)}
			</div>
		</div>
	);
};

export default ProductDetails;
