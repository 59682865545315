import React from "react";

import ContactUsImage from "../assets/images/contactUsImage.png";

const ContactUs = () => {
	return (
		<div>
			<div className="px-60 py-20">
				<div className="grid grid-cols-12">
					<div className="col-span-6">
						<img src={ContactUsImage} alt="contact us" />
					</div>
					<div className="col-span-6">
						<div className="flex items-center justify-center text-2xl font-semibold my-10 mt-20 uppercase">
							<div className="flex-grow border border-[#D9D9D9] mx-2" />
							Contact Us
							<div className="flex-grow border border-[#D9D9D9] mx-2" />
						</div>
						<div className="text-center px-12 text-[#797979]">
							Contact us on sales@vappii.bionext.pk or call us at +971 54 237
							2116, we strive to respond to all inquiries within 48 hours from
							Monday to Friday.
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactUs;
