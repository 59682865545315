import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './App.css';
import FreeDeliveryBar from './components/FreeDeliveryBar';
import Header from './components/Header';
import { AuthProvider } from './contextApi/AuthContext';
import { CartProvider } from './contextApi/CartContext';
import { MainProvider } from './contextApi/MainContext';
import { router } from './navigation/routes';
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <ToastContainer theme='colored' position='top-center' pauseOnHover={false} />
      <MainProvider>
        <AuthProvider>
          <CartProvider>
            <RouterProvider router={router} >
              <FreeDeliveryBar />
              <Header />
            </RouterProvider>
          </CartProvider>
        </AuthProvider>
      </MainProvider>
    </div>
  );
}

export default App;
