import React from "react";

const CheckoutDeliveryTable = ({ tableData }) => {
	return (
		<div className="w-full">
			{tableData?.map((rowData, index) => (
				<div className="border-b flex flex-wrap md:flex-nowrap justify-between" key={index}>
					<div className="py-2">{rowData?.field}</div>
					<div className="text-wrap">{rowData?.value}</div>
					<div>Change</div>
				</div>
			))}
		</div>
	);
};

export default CheckoutDeliveryTable;

{
	/* <table className="w-full overflow-x-scroll">
			<tbody className="text-[#9C9C9C]">
				{tableData.map((rowData, index) => (
					<tr className="border-b" key={index}>
						<td className="py-2">{rowData.field}</td>
						<td className="text-wrap">{rowData.value}</td>
						<td>Change</td>
					</tr>
				))}
			</tbody>
		</table> */
}
