import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import TextError from "./TextError";
import { getCities } from "../../services/checkout";

const Information = ({ formik }) => {
	const [cities, setCities] = useState([]);

	const handleGetCities = async () => {
		try {
			const response = await getCities();
			console.log("Response from getting cities", response);
		} catch (error) {
			console.log("Error from getting cities: ", error);
		}
	};

	useEffect(() => {
		handleGetCities();
	}, []);

	return (
		<div>
			<ErrorMessage name="email" component={TextError} />
			<Field
				type="email"
				name="email"
				placeholder="Email"
				formik={formik}
				className={`p-3 border border-[#135BFF] w-full my-3 rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
					formik.errors.email && "border-red-500"
				}`}
			/>
			<ErrorMessage name="telephone" component={TextError} />
			<Field
				type="text"
				name="telephone"
				placeholder="Telephone"
				formik={formik}
				className={`p-3 border border-[#135BFF] w-full my-3 rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
					formik.errors.telephone && "border-red-500"
				}`}
			/>
			<div className="flex items-center flex-col lg:flex-row gap-4 my-3">
				<div className="w-full">
					<ErrorMessage name="firstName" component={TextError} />
					<Field
						type="text"
						name="firstName"
						placeholder="First Name"
						formik={formik}
						className={`p-3 border border-[#135BFF] w-full rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
							formik.errors.firstName && "border-red-500"
						}`}
					/>
				</div>
				<div className="w-full">
					<ErrorMessage name="lastName" component={TextError} />
					<Field
						type="text"
						name="lastName"
						placeholder="Last Name"
						formik={formik}
						className={`p-3 border border-[#135BFF] w-full rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
							formik.errors.lastName && "border-red-500"
						}`}
					/>
				</div>
			</div>
			<ErrorMessage name="streetAddress" component={TextError} />
			<Field
				type="text"
				name="streetAddress"
				placeholder="Street Address"
				formik={formik}
				className={`p-3 border border-[#135BFF] w-full my-3 rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
					formik.errors.streetAddress && "border-red-500"
				}`}
			/>
			<div className="flex items-center flex-col lg:flex-row gap-4 my-3">
				<div className="w-full">
					<ErrorMessage name="postCode" component={TextError} />
					<Field
						type="text"
						name="postCode"
						placeholder="Post Code / ZIP"
						formik={formik}
						className={`p-3 border border-[#135BFF] w-full rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
							formik.errors.postCode && "border-red-500"
						}`}
					/>
				</div>
				<div className="w-full">
					<ErrorMessage name="townOrCity" component={TextError} />
					<Field
						type="text"
						name="townOrCity"
						placeholder="Town / City"
						formik={formik}
						className={`p-3 border border-[#135BFF] w-full rounded-md focus:border-[#135BFF] focus:outline-none focus:border-[135BFF] focus:border-2 ${
							formik.errors.townOrCity && "border-red-500"
						}`}
					/>
				</div>
			</div>
		</div>
	);
};

export default Information;
