import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const DiscountBanner = ({ discount }) => {
	const { secondaryBaseColor } = useContext(MainContext);
	return (
		<div className="absolute right-0 top-0 h-16 w-16 z-50">
			<div
				className="absolute transform rotate-45 text-center text-white font-semibold py-1 right-[-50px] top-[20px] w-[170px]"
				style={{ backgroundColor: secondaryBaseColor }}
			>
				{discount} off
			</div>
		</div>
	);
};

export default DiscountBanner;
