import React from "react";

const PackComponent = ({ text, selected }) => {
	return (
		<div
			className="inline-flex items-center justify-center flex-row gap-1 py-1 px-2 border border-black rounded w-full"
			style={{ backgroundColor: selected && "black", color: selected && "white" }}
		>
			{/* Text */}
			<div className="text-center text-xs sm:text-sm md:text-base leading-tight">
				{text.split(" ")[0]}
			</div>
			<div className="text-center text-xs sm:text-sm md:text-base leading-tight">
				{text.split(" ")[1]}
			</div>
		</div>
	);
};

export default PackComponent;
