import React from "react";
import AddressInput from "../../components/AddressInput";

const Addresses = () => {
	return (
		<div>
			<div className="text-[#838383]">
				The following addresses will be used on the checkout page by default.
			</div>
			<div className="flex items-center justify-between text-[#444343] gap-20 mt-6">
				<AddressInput placeholder="Billing Address" />
				<AddressInput placeholder="Delivery Address" />
			</div>
		</div>
	);
};

export default Addresses;
