import React from "react";
import ProductCard from "../../components/ProductCard";

const Dashboard = () => {
	return (
		<div>
			<div className="text-xl font-semibold">Welcome to you Account Page</div>
			<div className="text-[#797979] text-sm">
				Hi nazshabnum35, today is a great day to check your account page. You
				can check also:
			</div>
			<div className="flex items-center gap-6">
				<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
					Recent Orders
				</button>
				<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
					Addresses
				</button>
				<button className="w-full mt-3 bg-[#59008E] text-white p-2 rounded-full">
					Account Details
				</button>
			</div>
			<div className="flex items-center justify-center mt-6 text-lg font-bold">
				You May Also Like ...
			</div>
			<div className="flex items-center">
				<ProductCard />
				<ProductCard />
				<ProductCard />
			</div>
		</div>
	);
};

export default Dashboard;
