import React, { useContext } from "react";
import SocialIcons from "../assets/images/socialIconsGroup.png";
import PaymentOptionGroupIcon from "../assets/images/paymentOptionGroup.png";
import { Link } from "react-router-dom";
import { MainContext } from "../contextApi/MainContext";

const Footer = () => {
	const { secondaryBaseColor } = useContext(MainContext);

	return (
		<div className="px-0 md:px-5 2xl:px-60 mt-10">
			<div className="border-t border-[#595959] py-10">
				<div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-8 text-[#595959]">
					{/* All Policy Section */}
					<div className="flex flex-col items-center gap-2 lg:items-start">
						<div className="font-bold text-lg">All Policy</div>
						<div>Return Policy</div>
						<div>Privacy Policy</div>
						<Link to="/contact-us">Contact Us</Link>
					</div>

					{/* Company Section */}
					<div className="flex flex-col text-center items-center gap-2 lg:items-start">
						<div className="font-bold text-lg">Company</div>
						<div className="font-black">eMiracle L.L.C-FZ</div>
						<div className="font-black">P.O.Box 9305 DUBAI</div>
						<div>About Us</div>
						<div>Terms & Condition</div>
					</div>

					{/* Stay Connected Section */}
					<div className="flex flex-col items-center gap-2 lg:items-start">
						<div className="font-bold text-lg">STAY CONNECTED</div>
						<div>info@snussii.com</div>
						<div>+97154 237 2116</div>
						<div>
							<img
								src={SocialIcons}
								className="w-32 h-auto"
								alt="social icons"
							/>
						</div>
						<div className="font-bold">PAYMENT OPTIONS</div>
						<div>
							<img
								src={PaymentOptionGroupIcon}
								className="w-32 h-auto"
								alt="payment options"
							/>
						</div>
					</div>

					{/* Subscribe Section */}
					<div className="flex flex-col gap-4 items-center lg:items-start">
						<button
							className="text-white p-4 rounded-md w-full lg:w-auto"
							style={{ backgroundColor: secondaryBaseColor }}
						>
							Become an Authorized Dealer
						</button>
						<input
							placeholder="Enter your Email here"
							className="p-4 border border-[#D9D9D9] rounded-md w-full lg:w-auto"
						/>
						<button
							className="text-white p-3 rounded-md w-full lg:w-auto"
							style={{ backgroundColor: secondaryBaseColor }}
						>
							SUBSCRIBE
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
