import React, { useContext } from "react";
import { MainContext } from "../contextApi/MainContext";

const Star = ({ selected }) => {
	const { baseColor } = useContext(MainContext);

	return (
		<span
			className="cursor-pointer text-xl md:text-2xl"
			style={{
				color: selected ? baseColor : "gray",
			}}
		>
			{" "}
			★{" "}
		</span>
	);
};

export default Star;
