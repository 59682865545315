import React from "react";
import NoDataAvailableDashboard from "../../components/NoDataAvailableDashboard";

const Orders = () => {
	return (
		<div>
			<NoDataAvailableDashboard text="No order has been made yet." />
		</div>
	);
};

export default Orders;
