import React from "react";

import ExclamationMark from "../assets/images/exclamationMark.svg";

const NoDataAvailableDashboard = ({ text }) => {
	return (
		<div className="w-full bg-[#59008E] p-4 flex items-center justify-between">
			<div className="flex items-center gap-2 text-white">
				<img src={ExclamationMark} />
				{text}
			</div>
			<div className="text-white underline font-medium">Browse Products</div>
		</div>
	);
};

export default NoDataAvailableDashboard;
